import {
  DESKTOP_MEDIA_BP,
  MEDIA_DOMAIN,
  MEDIA_PREFIX,
  MOBILE_MEDIA_BP,
  RECIPE_DOMAIN,
  RECIPE_PREFIX,
} from 'config/config';

import type { RecipeResult, RecipeImage, HeroBannerImages } from 'types/Recipes';

const MOBILE_IMAGE_WIDTH = 290;
const MOBILE_IMAGE_HEIGHT = 400;
const DESKTOP_IMAGE_WIDTH = 1272;

const transformImageSrc = (src: string) => {
  if (!src) return '';
  if (process.env.STORYBOOK) return src;

  try {
    const tempUrl = new URL(src);
    const origin = tempUrl.origin;

    if (src.includes(RECIPE_DOMAIN)) {
      return src.replace(origin, RECIPE_PREFIX);
    }

    if (src.includes(MEDIA_DOMAIN)) {
      return src.replace(origin, MEDIA_PREFIX);
    }
  } catch (error) {
    return src;
  }

  return src;
};

const getRecipeImageForHeroBanner = (recipe: RecipeResult): HeroBannerImages => {
  const images: HeroBannerImages = {
    mobile: '',
    desktop: '',
  };
  recipe?.Images?.[0]?.Formats.map((image: RecipeImage) => {
    if (image.Width === MOBILE_IMAGE_WIDTH && image.Height === MOBILE_IMAGE_HEIGHT) {
      images.mobile = transformImageSrc(image.Url);
    }
    if (image.Width === DESKTOP_IMAGE_WIDTH) {
      images.desktop = transformImageSrc(image.Url);
    }
  });
  return images;
};

const getPictureSources = (image?: any) => {
  const sources = [];
  if (image?.mobile) {
    sources.push({
      srcSet: getImageUrlWithCompressionParams(image.mobile, 75, image.width, image.mimeType, true),
      type: 'image/webp',
      media: MOBILE_MEDIA_BP,
      width: image.width,
      height: image.width,
      mimeType: image.mimeType,
    });
  }
  if (image?.mobile === '') {
    sources.push({
      srcSet: getImageUrlWithCompressionParams(
        image.tablet || image.hero || image.desktop,
        75,
        image.width,
        image.mimeType,
        true,
      ),
      type: 'image/webp',
      media: MOBILE_MEDIA_BP,
      width: image.width,
      height: image.width,
      mimeType: image.mimeType,
    });
  }
  if (image?.desktop) {
    sources.push({
      srcSet: getImageUrlWithCompressionParams(image.desktop, 75, image.width, image.mimeType),
      type: 'image/webp',
      media: DESKTOP_MEDIA_BP,
      width: image.width,
      height: image.width,
      mimeType: image.mimeType,
    });
  }
  return sources;
};

const getMediaDomainSrcWithId = (src: string) => {
  const ID = src?.split('/')?.at(-1);
  return transformImageSrc(`https://${MEDIA_DOMAIN}/${ID}`);
};

const serveImageUnoptimized = (src: string, quality?: number, mimeType?: string) => {
  if (mimeType === 'image/svg+xml') {
    return true;
  }

  if (quality === 100) {
    return true;
  }

  if (!src.includes(MEDIA_DOMAIN)) {
    return true;
  }

  return false;
};

const getImageUrlWithCompressionParams = (
  src: string,
  quality: number | undefined,
  width: number | undefined,
  mimeType?: string | undefined,
  isMobileImage?: boolean,
) => {
  if (!src) {
    return '';
  }

  if (src.includes(RECIPE_DOMAIN)) {
    return transformImageSrc(src);
  }

  if (serveImageUnoptimized(src, quality)) {
    return transformImageSrc(src);
  }

  const ID = src?.split('/')?.at(-1);

  if (mimeType === 'image/svg+xml') {
    return getMediaDomainSrcWithId(src);
  }

  if (mimeType === 'image/tiff') {
    const params = [
      'output=format:webp',
      `resize=fit:max,width:${width || 1920}`,
      `quality=value:${quality || 75}`,
      'compress',
    ];
    return transformImageSrc(`https://${MEDIA_DOMAIN}/${params.join('/')}/${ID}`);
  }

  const DEFAULT_IMAGE_WIDTH = isMobileImage ? 800 : 1920;
  const newSrc = src?.replace(ID ?? '', '');
  const providedParams = newSrc.match(`https://${MEDIA_DOMAIN}/?(.*)`);
  const maxWidthImageParam =
    src.includes('width') || src.includes('resize')
      ? providedParams?.[1]
      : `resize=fit:max,width:${width || DEFAULT_IMAGE_WIDTH}`;
  const isOutputAlreadyDefined = src.includes('output');
  const params = [
    !isOutputAlreadyDefined ? 'output=format:webp' : '',
    maxWidthImageParam,
    `quality=value:${quality || 75}`,
    'compress',
  ];

  const joinedParams = params.join('/');
  return transformImageSrc(`https://${MEDIA_DOMAIN}/${joinedParams}/${ID}`).replace(/\/+/g, '/');
};

export const ImagesService = {
  getPictureSources,
  getImageUrlWithCompressionParams,
  getRecipeImageForHeroBanner,
  transformImageSrc,
  serveImageUnoptimized,
};
