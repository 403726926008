import { Border, Button, Container } from './styles';

export const LiquidButton = ({
  width,
  height,
  color,
  text,
  textColor,
  useCustomColorsAsImportant = false,
  isWidthAuto = false,
  onButtonClick = () => {},
}: {
  width: number;
  height: number;
  color: string;
  text: string;
  textColor: string;
  useCustomColorsAsImportant?: boolean;
  isWidthAuto?: boolean;
  onButtonClick?: () => void;
}) => {
  return (
    <>
      <Container width={width} height={height} isWidthAuto={isWidthAuto} onClick={onButtonClick}>
        <Border
          width={width}
          height={height}
          color={textColor}
          useCustomColorsAsImportant={useCustomColorsAsImportant}
          isWidthAuto={isWidthAuto}
        />
        <Button
          textColor={textColor}
          bgColor={color}
          width={width}
          height={height}
          useCustomColorsAsImportant={useCustomColorsAsImportant}
          isWidthAuto={isWidthAuto}
        >
          {text}
        </Button>
      </Container>
    </>
  );
};
