export const COLORS = {
  primary: '#A45A2A',
  secondary: '#EFDBB2',
  error: '#E22C43',
  white: '#ffffff',
  black: '#000000',
  darkGray: '#5a5e5a',
};

/**
 * TODO: create variables if needed and use in code
 * #292b29
 * #5a5e5a
 * #f4f5f4
 * #d4ded3
 * #BBC4BA
 * #ccc
 * #DF002C
 * #FA85BD
 */
